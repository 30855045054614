<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des contributeurs
      </span>
      <div class="row p-0 m-0 justify-content-end">
        <CustomAddButton
          :disabled="
            disabled ||
            !(
              (TASK.responsable && AuthenticatedUser
                ? TASK.responsable.id === AuthenticatedUser.id
                : false) ||
              isAdmin ||
              isSuper
            )
          "
          text="Ajouter un contributeur"
          @click="addItem"
          class="px-2 px-md-4"
        />
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <b-table
      responsive
      :items="contributers"
      :fields="tableFields"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      striped
      bordered
    >
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(user)="data">
        <TableResponsable
          :responsable="data.value"
          v-if="!data.item.hasOwnProperty('editMode')"
        />
        <div class="row col-12 p-0 m-0" v-else>
          <div
            class="font-weight-normal p-0 form-control"
            style="padding: 0 !important; height: fit-content"
            :class="{
              'is-invalid': $v.selectedUser.$error && $v.selectedUser.$dirty,
            }"
          >
            <v-select
              appendToBody
              label="text"
              :reduce="(responsable) => responsable.value"
              :options="responsables"
              v-model="selectedUser"
              :value="data.value.id"
              style="min-width: max-content"
              class="bg-transparent col-12 p-0"
              placeholder="contributeur"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </div>

          <!-- ERROR MESSAGES -->
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.selectedUser.$dirty"
          >
            {{ !$v.selectedUser.required ? "Champ obligatoire" : "" }}
          </span>
          <!--  -->
        </div>
      </template>

      <template #cell(commentaire)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          v-html="data.value"
        ></span>
        <input
          v-else
          type="text"
          class="form-control"
          v-model="data.item.commentaire"
          placeholder="Commentaire"
        />
      </template>
      <template #cell(nbrejours)="data">
        <span
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          v-html="data.value"
        ></span>
        <input
          v-else
          type="number"
          min="0"
          v-model="data.item.nbrejours"
          class="form-control"
          placeholder="etp"
        />
      </template>

      <template #cell(status)="data">
        <!-- {{ $v.contributers.$each[0].status }} -->
        <span
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          v-html="data.value"
        ></span>
        <div class="row col-12 p-0 m-0" v-else>
          <div
            class="font-weight-normal p-0 form-control"
            style="padding: 0 !important; height: fit-content"
            :class="{
              'is-invalid':
                $v.contributers.$each[data.index].status.$error &&
                $v.contributers.$each[data.index].status.$dirty,
            }"
          >
            <v-select
              class="col-12 p-0 bg-transparent"
              :options="CONTRIBUTEUR_STATUS"
              selected
              style="min-width: max-content"
              v-model="data.item.status"
              appendToBody
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </div>
          <!-- ERROR MESSAGES -->
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.contributers.$each[data.index].status.$dirty"
          >
            {{
              !$v.contributers.$each[data.index].status.required
                ? "Champ obligatoire"
                : ""
            }}
          </span>
          <!--  -->
        </div>
      </template>
      <template #cell(conso)="data">
        <b-progress
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          max="100"
          show-progress
          style="background-color: #cacccd !important"
          height="15px"
        >
          <b-progress-bar
            :value="data.value"
            :label="`${data.value}%`"
            :style="`background: ${
              data.value > 40 && data.value <= 75
                ? '#ffde59'
                : data.value > 75
                ? '#d90429'
                : '#38b000'
            }`"
          ></b-progress-bar>
        </b-progress>
        <section v-else class="simple-slider">
          <vue-slide-bar class="pt-3" v-model="data.item.conso" />
        </section>
      </template>

      <template #cell(avancement)="data">
        <b-progress
          v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          max="100"
          show-progress
          style="background-color: #cacccd !important"
          height="15px"
        >
          <b-progress-bar
            :value="data.value"
            :label="`${data.value}%`"
            :style="`background-color: ${
              data.value > 20 && data.value <= 70
                ? '#f5f245'
                : data.value > 70
                ? '#23d92c'
                : '#f5ba45'
            }`"
          ></b-progress-bar>
        </b-progress>
        <section v-else class="simple-slider">
          <vue-slide-bar class="pt-3" v-model="data.item.avancement" />
        </section>
      </template>
      <template #cell(actions)="data">
        <table-actions
          class="justify-content-end"
          :actions="actions(data.item.user ? data.item.user.id : 0)"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem(data.index, data.item)"
          @cancelItem="cancelItem(data.item, data.index)"
          @deleteItem="deleteItem(data.item.id)"
          @editItem="editItem(data.item)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions";
import VueSlideBar from "vue-slide-bar";
import TableResponsable from "@/components/custom/TableResponsable";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators"; //validation
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableActions,
    TableResponsable,
    VueSlideBar,
    CustomAddButton,
  },
  props: {
    contributers: Array,
    responsables: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        { key: "user", label: "Contributeurs", sortable: false },
        { key: "commentaire", label: "Commentaire", sortable: false },
        { key: "nbrejours", label: "ETP", sortable: false },
        { key: "status", label: "Statut", sortable: false },
        { key: "conso", label: "Conso", sortable: false },
        { key: "avancement", label: "Avancement", sortable: false },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],

      selectedUser: null,
    };
  },
  validations: {
    selectedUser: {
      required,
    },
    contributers: {
      $each: {
        status: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters("status", ["CONTRIBUTEUR_STATUS"]), 
    ...mapGetters(["AuthenticatedUser"]),
    ...mapGetters("task", ["TASK"]),
    ...mapGetters(["isSuper", "isAdmin", "AuthenticatedUser"]),

    rows() {
      return this.contributers.length;
    },
  },
  created() {
    if (this.$route.params.id)
      this.$store.dispatch("status/fetchContributeurStatus");
  },
  methods: {
    actions(userId) {
      return (this.AuthenticatedUser
        ? this.AuthenticatedUser.id === this.TASK.responsable.id ||
          this.AuthenticatedUser.id === userId
        : false) ||
        this.isAdmin ||
        this.isSuper
        ? ["edit", "delete"]
        : [];
    },
    confirmItem: function (index, newContributer) {
      window.console.log(newContributer);
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var contribution = {
          ...newContributer,
          user: "/api/users/" + this.selectedUser,
          commentaire: newContributer.commentaire,
          nbrejours: parseInt(newContributer.nbrejours),
          status: newContributer.status,
          conso: "" + newContributer.conso,
          avancement: newContributer.avancement,
          task: `/api/transformation/tasks/${this.$route.params.id}`,
        };
        delete contribution.old, contribution.slug, contribution.editMode;
        if (newContributer.id)
          this.$store
            .dispatch("contribution/updateContribution", contribution)
            .then(() => {
              this.$store
                .dispatch("task/fetchTask", this.$route.params.id)
                .then(() => {
                  Swal.fire("La contribution est mise à jour !", "", "success");
                  this.selectedUser = null;
                });
            });
        else {
          this.$store
            .dispatch("contribution/createContribution", contribution)
            .then(() => {
              this.$store
                .dispatch("task/fetchTask", this.$route.params.id)
                .then(() => {
                  Swal.fire("La contribution est bien créée !", "", "success");
                  this.selectedUser = null;
                });
            });
        }
      }
    },
    cancelItem: function (item) {
      window.console.log(item);
      if (
        item.user === null &&
        item.commentaire === "" &&
        item.nbrejours === 0 &&
        item.status === "" &&
        item.conso === 0 &&
        item.avancement === 0
      ) {
        this.$store.dispatch("task/fetchTask", this.$route.params.id);
      } else {
        item.user = null;
        item.commentaire = "";
        item.nbrejours = 0;
        item.status = "";
        item.conso = 0;
        item.avancement = 0;
        this.selectedUser = null;
      }
    },
    editItem(item) {
      this.selectedUser = item.user.id;
      this.$store.dispatch("task/fetchTask", this.$route.params.id).then(() => {
        this.contributers = this.contributers.filter(
          (contributer) => contributer.old
        ); // delete incommited contributers
        this.contributers = this.contributers.map((contributer) =>
          item.id === contributer.id
            ? { ...contributer, editMode: true }
            : contributer
        );
      });
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("contribution/deleteContribution", id)
            .then(() => {
              this.$store
                .dispatch("task/fetchTask", this.$route.params.id)
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                }); // get current task
            });
        }
      });
    },
    addItem() {
      if (
        this.TASK.responsable.id === this.AuthenticatedUser.id ||
        this.isAdmin ||
        this.isSuper
      ) {
        if (!this.contributers.some((contribute) => contribute.editMode)) {
          this.currentPage = 1;
          this.contributers = [
            {
              user: "",
              commentaire: "",
              nbrejours: 0,
              status: "Ouvert",
              role: "role deleted",
              conso: 0,
              avancement: 0,
              actions: ["edit", "delete"],
              editMode: true,
            },
            ...this.contributers,
          ];
        }
      }
    },
  },
};
</script>

<style>
@media (min-width: 600px) {
  .align-btn-table {
    position: relative;
    left: -115px;
  }
}
</style>
